<template>
  <div class="markdown-editor">
    <div class="editor-container">
    <textarea @input="$emit('update:markdownText', $event.target.value)" :value="markdownText" v-if="!showMD" id="md-editor">
    </textarea>
      <div v-html="markdownHTML" v-else class="markdown">

      </div>
    </div>
    <div class="editor-actions" :class="{'preview': showMD}">
      <div class="edit-md" v-if="!showMD">
        <button class="material-icons" @click="format('**','**')">
          format_bold
        </button>
        <button class="material-icons" @click="format('_','_')">
          format_italic
        </button>
        <button class="material-icons" @click="format('<','>')">
          public
        </button>
        <button class="material-icons" @click="format('- ','')">
          format_list_bulleted
        </button>
        <button @click="format('# ','')">
          <b>H1</b>
        </button>
        <button @click="format('## ','')">
          <b>H2</b>
        </button>
        <button  @click="format('### ','')">
          <b>H3</b>
        </button>

      </div>
      <button @click="showMD=!showMD" class="material-icons">{{showMD?'visibility_off':'visibility'}}</button>
    </div>


  </div>



</template>

<script>
import {marked} from 'marked';
import DOMPurify from 'dompurify'
export default {
  name: "MarkdownEditor",
  props:{
    markdownText:String
  },
  data(){
    return{
      showMD:false,
    }
  },
  computed:{
    markdownHTML(){
      return DOMPurify.sanitize(marked(this.markdownText))
    }
  },
  methods:{
    format(front,end) {
      let el = document.getElementById('md-editor')
      let startPos = el.selectionStart;
      let endPos = el.selectionEnd;
      let selectedText=el.value.substring(startPos,endPos)
     if(startPos != endPos) {
       if(selectedText.substring(0,front.length)===front&&selectedText.substring(selectedText.length-end.length,selectedText.length)===end){

         el.value = el.value.substring(0,startPos) + selectedText.replaceAll(front,'').replaceAll(end,'')+ el.value.substring(endPos, el.value.length)
       }else{
         el.value = el.value.substring(0, startPos) + front+selectedText.trim()+ end + el.value.substring(endPos, el.value.length)
       }
        this.$emit('update:markdownText', el.value)
        el.focus()
        el.selectionEnd = endPos + front.length
      }
      else if (startPos || endPos === '0') {
        el.value = el.value.substring(0, startPos) + front+end + el.value.substring(endPos, el.value.length)
        this.$emit('update:markdownText', el.value)
        el.focus()
        el.selectionEnd = endPos + front.length
      }else{
        el.value+=front+end
        el.focus()
        el.selectionEnd=el.value.length-end.length;
      }
    },

  }

}
</script>

<style scoped lang=scss>
@import "../../styles/themes.scss";
.markdown-editor{
  @include theme {
    box-shadow: theme-get('shadow-1');
  }
}
  .editor-container
  {
    textarea{
      resize: none;
      padding:0;
      margin:0;
      border: none;
      outline:none;
      width: 100%;
      height: 99%;
      @include theme {
        color: theme-get('text-1');
        background-color: theme-get('input-background');
      }
    }
    border-radius:  12px  12px 0 0;
    padding:12px;
    height: 200px;
    overflow-y: auto;
    @include theme {
      color: theme-get('text-1');
      background-color: theme-get('input-background');

    }
  }
.editor-actions{
  &.preview{
    justify-content: flex-end;
  }
  .edit-md{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  button{
    cursor:pointer;
    border:none;
    background-color: transparent;
    border-radius: 100%;
    padding:8px;
    &:hover{
      @include theme {
        background-color: theme-get('primary-hover');
        color: theme-get('primary-color');
      }
    }
    @include theme {
      color: theme-get('text-1');
    }
  }

  @include theme {
    border-top: rgba(0,0,0,0.1)  1px solid;
    color: theme-get('text-1');
    background-color: theme-get('input-background');

  }
  padding:12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius:   0 0 12px  12px;
}
</style>
